import styled from "styled-components";
import { Link } from "gatsby";

export const Container = styled.div`
  display: flex;
  font-family: 'Montserrat', sans-serif;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;

export const LinkTo = styled(Link)`
    color: #545454;
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
`;
